import React from 'react'
import { useTableReturnType, useIsNewTable } from '@components/TableV2/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  RiskLevel,
  RiskStatsInterface,
  RiskSummaryInterface,
  RiskTabData,
} from '@src/interfaces/risk'
import {
  riskActionColumn,
  riskAssigneeColumn,
  riskCategoryColumn,
  riskDueDateColumn,
  riskLevelColumn,
  riskReasonColumn,
  riskSolutionColumn,
  riskStatusColumn,
  riskSummaryColumn,
} from '@src/constants/columns/risk'
import { Box, MoreBar, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { InfoOutline } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TooltipContainer } from '@src/components/CommonSC/Tooltip'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

interface Props {
  data?: RiskTabData
  table: useTableReturnType<RiskSummaryInterface, RiskStatsInterface>
  isEmployee?: boolean
  useWrapper?: boolean
}

const ROW = (isEmployee: boolean): RowInterface<RiskSummaryInterface> => ({
  highlight: data => {
    if (data.risk_level === RiskLevel.high) {
      return Token.color.red_20
    }
    return ''
  },
  cells: [
    {
      ...riskSummaryColumn,
      width: 340,
    },
    {
      ...riskLevelColumn,
      width: 140,
    },
    {
      ...riskCategoryColumn,
      width: 140,
    },
    {
      ...(isEmployee ? teamNameColumn : riskAssigneeColumn),
      width: 140,
    },
    {
      ...riskReasonColumn,
      width: 160,
    },
    {
      ...riskSolutionColumn,
      width: 240,
    },
    {
      ...riskActionColumn,
      width: 80,
    },
    {
      ...riskDueDateColumn,
      width: 100,
    },
    {
      ...riskStatusColumn,
      width: 100,
    },
  ],
})

const mapRiskToTooltip = (risk: string) => {
  switch (risk) {
    case 'Very Low':
      return `No red or amber key risks
and
No red non-key risks`
    case 'Low':
      return `% amber risks (key and non-key)  >0%
`
    case 'Medium':
      return `% red risks  > 0%
or
% red key risks  >0%
or
% amber risks (key and non-key)  >=60%
`
    case 'High':
      return `% red risks  >=30%
or
% red key risks  >=10%
or
red risks (key and non-key) >=30%
`
    case 'Very High':
      return `% red risks  >=50%
or
% red key risks >=20%
or
red risks (key and non-key)
`
    default:
      return 'No risks recorded on the register'
  }
}

const Summary = ({ table, data, isEmployee = false }: Props) => {
  const isNewTable = useIsNewTable()

  return (
    <>
      {isNewTable ? null : (
        <Table.Widget.Info>
          {data?.risk_score && (
            <Stat
              label="Control Score"
              val={
                <Tooltip text={data?.risk_score_reason} placement="right">
                  {data?.risk_score}
                </Tooltip>
              }
            />
          )}
          {data?.risk_composite_index && (
            <Stat
              label="Risk score"
              val={
                <Tooltip
                  body={
                    <TooltipContainer>
                      <Box style={{ whiteSpace: 'pre' }}>
                        {mapRiskToTooltip(data?.risk_composite_index.name)}
                      </Box>
                    </TooltipContainer>
                  }
                  placement="right"
                >
                  {data?.risk_composite_index.name}
                </Tooltip>
              }
            />
          )}
          <Stat
            label="High"
            val={
              table.stats?.high !== undefined ? (
                <Tooltip
                  text="Can have serious financial, reputation or regualtory implications. Escalated to Executive Risk Committee and/or Board."
                  placement="right"
                >
                  <Text color="red">{table.stats?.high}</Text>
                </Tooltip>
              ) : undefined
            }
          />
          <Stat
            label="Medium"
            val={
              table.stats?.medium !== undefined ? (
                <Tooltip
                  text="Escalated to 2LOD(Second Line of Defence) and/or Sub-Committee (of Executive Risk Committee)."
                  placement="right"
                >
                  <Text color="warning">{table.stats?.medium}</Text>
                </Tooltip>
              ) : undefined
            }
            mr="s-32"
          />
        </Table.Widget.Info>
      )}

      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {data?.risk_score && (
            <MoreBar.Action
              target="_blank"
              rel="noreferrer noopener"
              href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1710528923?atlOrigin=eyJpIjoiMTE3MzQ0MDA2NjdkNGM3YmJlYmZjNmE1MWM3OTFkYWYiLCJwIjoiY29uZmx1ZW5jZS1jaGF0cy1pbnQifQ"
              use="a"
              useIcon={InfoOutline}
            >
              What are the risk and control scores?
            </MoreBar.Action>
          )}
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1430881442/Risks+and+Controls+Identification+and+Assessment"
            use="a"
            useIcon={InfoOutline}
          >
            How to manage risks?
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RiskSummaryInterface, RiskStatsInterface>
          name={TableNames.RiskSummary}
          useWindowScroll
          dataType="Risk"
          row={ROW(isEmployee)}
          {...table}
          noDataMessage="This team has no data on their Risk"
          hideCount={isNewTable}
        />
      </Table.Widget.Table>
    </>
  )
}

export default Summary
