import React from 'react'
import { useTable } from '@components/Table/hooks'
import {
  getTeamIssues,
  useTeamIssuesCounts,
  useTeamIssuesCategoryCounts,
} from '@src/api/issues'
import { TeamInterface } from '@src/interfaces/teams'
import { IssueInterface, IssueType } from '@src/interfaces/issues'
import { IssuesTable } from '@src/features/IssuesTable/IssuesTable'
import { TableNames } from '@src/constants/table'
import { AuditStatuses } from '@src/interfaces/audit'
import { useParams, Route } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { useGetOrganisationSettings } from '@src/api/settings'
import { IssuesCircles } from '@src/features/IssuesCircles/IssuesCircles'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable } from '@components/TableV2/hooks'

const categoryFilterMap: Record<string, IssueType> = {
  user: 'user_issue',
  product: 'product_audit',
  technical: 'technical_bug',
  issue: 'user',
}

const TeamIssuesTable = ({ data, type }: { data: TeamInterface; type: IssueType }) => {
  const isNewTable = useIsNewTable()
  const initialFilterBy = [
    {
      filters: [
        { id: AuditStatuses.PENDING_FILING, name: AuditStatuses.PENDING_FILING },
        { id: AuditStatuses.QUALITY_REVIEW, name: AuditStatuses.QUALITY_REVIEW },
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
      ],
      columnName: 'status',
      nonResettable: true,
    },
    {
      filters: isNewTable ? [] : [{ id: type, name: type }],
      columnName: 'category',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'severity_sorter',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'due_date',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<IssueInterface>(
    {
      getItems: getTeamIssues(data?.id),
    },
    initialFilterBy,
    initialSortBy,
  )

  const { data: counts } = useTeamIssuesCounts(data?.id, initialFilterBy)

  return (
    <IssuesTable
      table={table}
      name={TableNames.DepartmentIssues}
      counts={counts}
      showDepartments={false}
      showTeams={false}
      showIssueType={isNewTable || type === 'user_issue'}
      showIssueStatus={isNewTable || type === 'user'}
    />
  )
}

export const Issues = ({ data }: { data: TeamInterface }) => {
  const isNewTable = useIsNewTable()
  const params = useParams<{ subtab: string }>()
  const { data: settings } = useGetOrganisationSettings()
  const { data: issuesCategoryCounts } = useTeamIssuesCategoryCounts(data?.id)
  const featureFlags = useSelector(selectFeatureFlags)

  const bugTrackingEnabled = !!settings?.bugs_tracking?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const userIssuesEnabled = featureFlags?.includes(FeatureFlags.NewUserIssues)

  const type = categoryFilterMap[params.subtab]

  const tabs = [
    {
      title: 'Findings',
      path: ROUTES.FORMS.TEAM.ISSUES.FINDING,
      to: pathToUrl(ROUTES.FORMS.TEAM.ISSUES.FINDING, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.user_issue} />,
      canView: findingsEnabled,
    },
    {
      title: 'Technical Bugs',
      path: ROUTES.FORMS.TEAM.ISSUES.TECHNICAL,
      to: pathToUrl(ROUTES.FORMS.TEAM.ISSUES.TECHNICAL, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.technical_bug} />,
      canView: bugTrackingEnabled,
    },
    {
      title: 'User Issues',
      path: ROUTES.FORMS.TEAM.ISSUES.USER,
      to: pathToUrl(ROUTES.FORMS.TEAM.ISSUES.USER, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.user} />,
      canView: userIssuesEnabled,
    },
    {
      title: 'Product Audits',
      path: ROUTES.FORMS.TEAM.ISSUES.PRODUCT,
      to: pathToUrl(ROUTES.FORMS.TEAM.ISSUES.PRODUCT, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.product_audit} />,
      canView: bugTrackingEnabled,
    },
  ].filter(({ canView }) => canView)

  return (
    <Table.Widget>
      {isNewTable ? null : <TabBarNavigation isSubtab tabs={tabs} mb="s-24" />}
      {tabs.map(tab => (
        <Route exact path={tab.path} key={tab.path}>
          <TeamIssuesTable data={data} type={type} />
        </Route>
      ))}
    </Table.Widget>
  )
}
